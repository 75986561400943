<template>
  <div>
    <b-tabs
      content-class="mt-3"
      class="sticky-tabs d-block d-sm-block d-md-none d-lg-none"
    >
      <b-tab :title="$t('general.menu.my_programmes')">
        <div v-if="inProgressCourses.length > 0">
          <div
            class="d-flex d-sm-flex d-md-none d-lg-none justify-content-between"
          >
            <div class="responsive-course-card-title">
              <img
                src="@/assets/images/my-programmes/in-progress.svg"
                alt="not found"
                width="auto"
                height="auto"
              />
              {{ $t("my_programmes.tab.in_progress") }}
            </div>

            <div
              class="align-self-center"
              v-if="inProgressCourses.length > 5"
              @click="seeAllCourses('ip')"
            >
              {{ $t("general.see_all") }}
            </div>
          </div>
          <div class="responsive-course-card">
            <template v-for="(course, index) in filteredFiveInProgressCourses">
              <CourseCard
                :key="index"
                :courseImage="
                  course.image_url
                    ? course.image_url
                    : require('@/assets/images/illustration/course-illustration.svg')
                "
                :org="course.org"
                :courseTitle="course.title"
                :language="language"
                :pacing="course.pacing"
                :courseLanguage="course.language"
                :index="`responsive-course-card-${index}`"
                :currentServerTime="currentServerTime"
                @click="onCard(course)"
                @orgClick="onOrgClick(course)"
                :status="'CONTINUE_LEARNING'"
                :course="course"
              ></CourseCard>
            </template>
          </div>
        </div>
        <div v-if="startingSoonCourses.length">
          <div
            class="d-flex d-sm-flex d-md-none d-lg-none justify-content-between"
          >
            <div class="responsive-course-card-title">
              <img
                src="@/assets/images/my-programmes/starting-soon.svg"
                alt="not found"
                width="auto"
                height="auto"
              />
              {{ $t("my_programmes.tab.starting_soon") }}
            </div>

            <div
              class="align-self-center"
              v-if="startingSoonCourses.length > 5"
              @click="seeAllCourses('ss')"
            >
              {{ $t("general.see_all") }}
            </div>
          </div>
          <div class="responsive-course-card">
            <template v-for="(course, index) in filterFiveStartingSoonCourses">
              <CourseCard
                :key="index"
                :courseImage="
                  course.image_url
                    ? course.image_url
                    : require('@/assets/images/illustration/course-illustration.svg')
                "
                :org="course.org"
                :courseTitle="course.title"
                :language="language"
                :pacing="course.pacing"
                :courseLanguage="course.language"
                :index="`responsive-course-card-${index}`"
                :currentServerTime="currentServerTime"
                @click="onCard(course)"
                @orgClick="onOrgClick(course)"
                :status="'CONTINUE_LEARNING'"
                :course="course"
              ></CourseCard>
            </template>
          </div>
        </div>
        <div v-if="allCompletedCourses.length">
          <div
            class="d-flex d-sm-flex d-md-none d-lg-none justify-content-between"
          >
            <div class="responsive-course-card-title">
              <img
                src="@/assets/images/my-programmes/completed.svg"
                alt="not found"
                width="auto"
                height="auto"
              />
              {{ $t("my_programmes.tab.completed") }}
            </div>

            <div
              class="align-self-center"
              v-if="allCompletedCourses.length > 5"
              @click="seeAllCourses('co')"
            >
              {{ $t("general.see_all") }}
            </div>
          </div>
          <div class="responsive-course-card">
            <template v-for="(course, index) in filterFiveCompletedCourses">
              <CourseCard
                :key="index"
                :courseImage="
                  course.image_url
                    ? course.image_url
                    : require('@/assets/images/illustration/course-illustration.svg')
                "
                :org="course.org"
                :courseTitle="course.title"
                :language="language"
                :pacing="course.pacing"
                :courseLanguage="course.language"
                :index="`responsive-course-card-${index}`"
                :currentServerTime="currentServerTime"
                @click="onCard(course)"
                @orgClick="onOrgClick(course)"
                :status="'COMPLETED'"
                :course="course"
              ></CourseCard>
            </template>
          </div>
        </div>
        <div v-if="archivedCourses.length">
          <div
            class="d-flex d-sm-flex d-md-none d-lg-none justify-content-between"
          >
            <div class="responsive-course-card-title">
              <img
                src="@/assets/images/my-programmes/in-progress.svg"
                alt="not found"
                width="auto"
                height="auto"
              />
              {{ $t("my_programmes.tab.archived") }}
            </div>

            <div
              class="align-self-center"
              v-if="archivedCourses.length > 5"
              @click="seeAllCourses('ar')"
            >
              {{ $t("general.see_all") }}
            </div>
          </div>
          <div class="responsive-course-card">
            <template v-for="(course, index) in filterFiveArchivedCourses">
              <CourseCard
                :key="index"
                :courseImage="
                  course.image_url
                    ? course.image_url
                    : require('@/assets/images/illustration/course-illustration.svg')
                "
                :org="course.org"
                :courseTitle="course.title"
                :language="language"
                :pacing="course.pacing"
                :courseLanguage="course.language"
                :index="`responsive-course-card-${index}`"
                :currentServerTime="currentServerTime"
                @click="onCard(course)"
                @orgClick="onOrgClick(course)"
                :status="'ENROLLED'"
                :course="course"
              ></CourseCard>
            </template>
          </div>
        </div>
        <div
          class="mp__empty-sec"
          v-if="isLoading && allEnrolledCourses.length === 0"
        >
          <div>
            <img
              src="@/assets/images/not-found/in-progress-not-found.svg"
              alt="not found"
              width="auto"
              height="auto"
            />
            <div class="empty-sec-title">
              {{ $t("achievements.empty.title") }}
            </div>
            <div class="empty-sec-subtitle">
              {{ $t("achievements.empty.subtitle") }}
            </div>
            <b-button
              class="btn ob-btn empty-sec-button"
              @click="$router.push({ path: '/course-discovery' })"
            >
              {{ $t("my_programmes.discovery") }}
            </b-button>
          </div>
        </div>
      </b-tab>
      <b-tab
        :title="$t('general.menu.my_achievements')"
        @click="navigateTo('/achievements')"
      >
      </b-tab>
    </b-tabs>

    <b-sidebar
      v-model="seeAllSidebar"
      ref="seeAllSidebar"
      id="see-all-sidebar"
      no-header
      right
      shadow
    >
      <div class="see-all-sidebar-navbar">
        <div class="see-all-sidebar-navbar-back-btn" v-b-toggle.see-all-sidebar>
          <img
            src="@/assets/images/my-programmes/see-all/arrow-left.svg"
            alt="Arrow Left"
          />
        </div>
        <div class="see-all-sidebar-navbar-title">
          {{ tabs[tabIndex].name }}
        </div>
      </div>
      <div class="mp-result-found">
        <div v-if="courses.length > 0">
          {{ courses.length }} {{ $t("my_programmes.results_found") }}
        </div>
      </div>
      <template v-for="(course, index) in courses">
        <CourseCard
          :key="index"
          :courseImage="
            course.image_url
              ? course.image_url
              : require('@/assets/images/illustration/course-illustration.svg')
          "
          :org="course.org"
          :courseTitle="course.title"
          :language="language"
          :pacing="course.pacing"
          :courseLanguage="course.language"
          :index="`responsive-course-card-${index}`"
          :currentServerTime="currentServerTime"
          @click="onCard(course)"
          @orgClick="onOrgClick(course)"
          :status="'COMPLETED'"
          :course="course"
        ></CourseCard>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import fetchTabContentMixin from "@/components/MyLearning/mixins/fetchTabContent";

export default {
  mixins: [fetchTabContentMixin],
  data() {
    return {
      seeAllSidebar: false
    };
  },
  computed: {
    filteredFiveInProgressCourses() {
      return this.inProgressCourses.slice(0, 5);
    },
    filterFiveCompletedCourses() {
      return this.allCompletedCourses.slice(0, 5);
    },
    filterFiveStartingSoonCourses() {
      return this.startingSoonCourses.slice(0, 5);
    },
    filterFiveArchivedCourses() {
      return this.archivedCourses.slice(0, 5);
    }
  },
  methods: {
    navigateTo(path) {
      this.$router.push({ path: path }).catch(() => {});
    },
    seeAllCourses(id) {
      this.isLoading = true;
      this.seeAllSidebar = true;
      switch (id) {
        case "ip":
          this.tabIndex = 0;
          break;
        case "ss":
          this.tabIndex = 1;
          break;
        case "co":
          this.tabIndex = 2;
          break;
        case "ar":
          this.tabIndex = 3;
          break;
        default:
          return null;
      }
    }
  }
};
</script>
<style lang="scss">
.responsive-course-card-title {
  @include button-label;
  text-align: left;
  letter-spacing: 0.15px;
  margin: 24px 0 15px;
  color: $brand-neutral-700;

  img {
    margin-right: 12px;
  }
}

.responsive-course-card {
  display: flex;
  overflow: auto;
  gap: 16px;
  &::-webkit-scrollbar {
    display: none;
  }
}

#see-all-sidebar {
  .lx-course-card-mobile-view {
    width: 100%;
  }
}

// rtl layout
[dir="rtl"] {
  .mp-result-found {
    text-align: right;
  }
  .responsive-course-card-title {
    img {
      margin-right: 0px;
      margin-left: 12px;
    }
  }
}
</style>
