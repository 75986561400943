import { mapGetters } from "vuex";
import redirectToLearningSpace from "@/core/mixins/redirectToLearningSpace";

export default {
  mixins: [redirectToLearningSpace],
  data() {
    return {
      tabs: [],
      tabIndex: 0,
      isLoading: true
    };
  },
  computed: {
    ...mapGetters([
      "certificatesList",
      "enrolledCourses",
      "archivedCourses",
      "currentServerTime",
      "language"
    ]),
    courses() {
      switch (this.tabIndex) {
        case 0:
          return this.enrolledCourses;
        case 2:
          return this.archivedCourses;
        default:
          return [];
      }
    },
    mutationString() {
      switch (this.tabIndex) {
        case 0:
          return `SET_ENROLLED_COURSES`;
        case 2:
          return "SET_ARCHIVED_COURSES";
        default:
          return [];
      }
    }
  },
  created() {
    this.setTabContent();
    this.getEnrolledCourses();
  },
  methods: {
    setTabContent() {
      this.tabs = [
        {
          name: this.$t("my_learning.section.enrolled"),
          active: true,
          tabId: "en"
        },
        {
          name: "Achievements",
          active: false,
          tabId: "ac"
        },
        {
          name: this.$i18n.t("my_learning.tab.archived"),
          active: false,
          tabId: "ar"
        }
      ];
    },
    getEnrolledCourses() {
      this.$store.dispatch("getEnrolledCourses").then(() => {
        this.isLoading = false;
      });
    },
    getArchivedCourses() {
      this.$store.dispatch("getArchivedCourses").then(() => {
        this.isLoading = false;
      });
    },
    onOrgClick(c) {
      this.navigateToLearningSpace(c.org);
    }
  }
};
